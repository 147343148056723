import React from 'react';
import BigNumber from 'bignumber.js';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import MyPopover from 'src/components/styled/MyPopover';
import { formatCollateralDisplayShorten } from '../exchangeMethods/loan/utils';

const BalanceInfoPopover = props => {
  const { includeStablecoin } = props;
  const profileInterest = useSelector(state => state.auth.profileInterest);
  const collaterals = useSelector(state => state.account.collaterals);
  const { ConstantBalance, SmallInterestAmount, TrialBalance = 0, InstantCredit = 0, InstantCreditInterest = 0, Debt = 0 } = profileInterest;

  const availableBalance = new BigNumber(ConstantBalance)
    .dividedBy(100)
    .plus(SmallInterestAmount)
    .toFormat(2, BigNumber.ROUND_DOWN);

  const instantCredit = new BigNumber(InstantCredit)
    .plus(InstantCreditInterest)
    .plus(Debt)
    .dividedBy(100)
    .toFormat(2, BigNumber.ROUND_DOWN);

  const trialBalance = new BigNumber(TrialBalance)
    .dividedBy(100)
    .toFormat(2, BigNumber.ROUND_DOWN);

  return (
    <MyPopover
      type="solid"
      content={(
        <>
          <div>Available balance: <strong>${availableBalance}</strong></div>
          <div>Instant credit: <strong>${instantCredit}</strong></div>
          {includeStablecoin && collaterals.filter(e => e.collateral.isStablecoin).map(e => (
            <div key={e.id}>{e.collateral.symbol} balance: <strong>{formatCollateralDisplayShorten(e.balance)} {e.collateral.symbol}</strong></div>
          ))}
          {TrialBalance > 0 && <div>Trial: <strong>${trialBalance}</strong></div>}
        </>
      )}
    />
  );
};

BalanceInfoPopover.propTypes = {
  includeStablecoin: PropTypes.bool,
};

export default BalanceInfoPopover;
